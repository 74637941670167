import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { ROUTE_OFFER_SOFTWARE_TAB } from 'shared/paths';
import { setMagazineCookies } from 'utils/cookies';

// This is redirection from QR code with additional tracking
export default () => {
    useEffect(() => {
        setMagazineCookies();
        navigate(ROUTE_OFFER_SOFTWARE_TAB);
    }, []);

    return <></>;
};
